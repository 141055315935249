import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'hds-react';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
import ExternalLink from '../../../components/ExternalLink';
export const _frontmatter = {
  "slug": "/components/cookie-consent/api",
  "title": "CookieConsent - API"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "api",
      "style": {
        "position": "relative"
      }
    }}>{`API`}<a parentName="h2" {...{
        "href": "#api",
        "aria-label": "api permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "component-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Component properties`}<a parentName="h3" {...{
        "href": "#component-properties",
        "aria-label": "component properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Common texts, groups and cookie data can be found in `}<ExternalLink openInExternalDomainAriaLabel="Opens in a new domain" openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/blob/master/packages/react/src/components/cookieConsent/content.json" mdxType="ExternalLink">{`HDS-provided content file`}</ExternalLink>{`. The file contains all user interface strings, language options, descriptions for common cookie groups, and details about common Helsinki cookies. By default, this content file is used. You can also override parts of it and add your project-specific groups and cookies.`}</p>
    <h4 {...{
      "id": "contentsource",
      "style": {
        "position": "relative"
      }
    }}>{`ContentSource`}<a parentName="h4" {...{
        "href": "#contentsource",
        "aria-label": "contentsource permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The `}<inlineCode parentName="p">{`contentSource`}</inlineCode>{` property lists all content, language options, and callbacks for the component. The given source is merged with the default `}<inlineCode parentName="p">{`content.json`}</inlineCode>{` to create a content-object.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`siteName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Yes`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookie consent main heading.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`currentLanguage`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Yes`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Currently selected language. Used for picking translations from `}<inlineCode parentName="td">{`content.json`}</inlineCode>{`. Is also set to `}<inlineCode parentName="td">{`content.language.current`}</inlineCode>{`. Supported values are `}<inlineCode parentName="td">{`fi`}</inlineCode>{`, `}<inlineCode parentName="td">{`sv`}</inlineCode>{`, and `}<inlineCode parentName="td">{`en`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`requiredCookies`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required cookies can have partial data or all data described in the table below. Missing texts are auto-filled. See examples below this table: `}<InternalLink href="/components/cookie-consent/api#required-and-optional-cookies" mdxType="InternalLink">{`Required and optional cookies.`}</InternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`optionalCookies`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional cookies can have partial data or all data described in the table below. Missing texts are auto-filled. See examples below this table: `}<InternalLink href="/components/cookie-consent/api#required-and-optional-cookies" mdxType="InternalLink">{`Required and optional cookies.`}</InternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`requiredCookies.groups`}</inlineCode>{`, `}<inlineCode parentName="td">{`optionalCookies.groups`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of cookie groups. All cookies must belong to a group. Groups with the property `}<inlineCode parentName="td">{`commonGroup`}</inlineCode>{` will be auto-filled from `}<inlineCode parentName="td">{`content.json`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`requiredCookies.groups[].cookies`}</inlineCode>{`, `}<inlineCode parentName="td">{`requiredCookies.cookies`}</inlineCode>{`, `}<inlineCode parentName="td">{`optionalCookies.groups[].cookies`}</inlineCode>{`, `}<inlineCode parentName="td">{`optionalCookies.cookies`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of individual cookies. All cookies must belong to a group or they must have property `}<inlineCode parentName="td">{`commonGroup`}</inlineCode>{` or `}<inlineCode parentName="td">{`groupId`}</inlineCode>{`. The `}<inlineCode parentName="td">{`groupId`}</inlineCode>{` must match `}<inlineCode parentName="td">{`group.id`}</inlineCode>{` of a custom group and the `}<inlineCode parentName="td">{`commonGroup`}</inlineCode>{` must match groups in `}<inlineCode parentName="td">{`content.json`}</inlineCode>{`. Do not give both.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`texts`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User interface string overrides. By default, strings are fetched from `}<inlineCode parentName="td">{`content.json`}</inlineCode>{` and this object overrides one or multiple texts.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Overrides for default language options. By default, options are fetched from `}<inlineCode parentName="td">{`content.json`}</inlineCode>{` and this object overrides one or more properties. `}<inlineCode parentName="td">{`onLanguageChange`}</inlineCode>{` callback is also added here.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onAllConsentsGiven`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See Callbacks section below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onConsentsParsed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`See Callbacks section below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`noCommonConsentCookie`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Consents are saved to a cookie and this option can prevent showing that cookie in the list of consents. If this is not true, `}<inlineCode parentName="td">{`requiredConsents`}</inlineCode>{` are always shown and a common group “sharedConsents” is added to it and also a common cookie “helConsentCookie” to that group. All content for those is in `}<inlineCode parentName="td">{`content.json`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`focusTargetSelector`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When the modal is closed or the ESC key is pressed when it is open, the focus must be shifted outside the modal. This defines the element to set focus to with this selector (string). Not needed when modal is not used. Throws an error is not set for modal.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:ContentSource object properties]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "contentsource-examples",
      "style": {
        "position": "relative"
      }
    }}>{`ContentSource examples`}<a parentName="h5" {...{
        "href": "#contentsource-examples",
        "aria-label": "contentsource examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h5>
    <p>{`You can find examples of ContentSource property contents in `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-cookieconsent--english-modal-version" mdxType="Link">{`the HDS CookieConsent Storybook examples`}</Link>{`.`}</p>
    <h4 {...{
      "id": "cookiedomain",
      "style": {
        "position": "relative"
      }
    }}>{`CookieDomain`}<a parentName="h4" {...{
        "href": "#cookiedomain",
        "aria-label": "cookiedomain permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`The cookie domain is automatically detected from the site the component is on. By default, the component sets the cookie domain to be the root domain with `}<inlineCode parentName="p">{`*.`}</inlineCode>{` This way the cookie consents can be read from all subdomains. For example `}<inlineCode parentName="p">{`*.hel.fi`}</inlineCode>{`.`}</p>
    <p>{`If your site domain is included in the `}<a parentName="p" {...{
        "href": "https://publicsuffix.org/"
      }}>{`public suffix list`}</a>{` or you do not wish to expose your cookies to subdomains, or you want to set the domain manually for some reason, then use the `}<inlineCode parentName="p">{`cookieDomain`}</inlineCode>{` property.`}</p>
    <p>{`Cookies for domains on the public suffix list cannot accept subdomain cookies. E.g. `}<inlineCode parentName="p">{`github.io`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "detailed-info-about-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Detailed info about properties`}<a parentName="h4" {...{
        "href": "#detailed-info-about-properties",
        "aria-label": "detailed info about properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h5 {...{
      "id": "required-and-optional-cookies",
      "style": {
        "position": "relative"
      }
    }}>{`Required and optional cookies`}<a parentName="h5" {...{
        "href": "#required-and-optional-cookies",
        "aria-label": "required and optional cookies permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`requiredCookies`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of required consents and their descriptions.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`optionalCookies`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of optional consents and their descriptions.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`*.title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Title for the consent group. Optional.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`*.text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description for the consent group. Optional.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`*.checkboxAriaDescription`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria description of the checkbox shown with the category. Optional.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`*.groups`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional list of groups. More info is below: `}<InternalLink href="/components/cookie-consent/api#cookie-groups" mdxType="InternalLink">{`Cookie groups.`}</InternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`*.cookies`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional list of cookies. More info is below: `}<InternalLink href="/components/cookie-consent/api#cookie-information" mdxType="InternalLink">{`Cookie information.`}</InternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Cookie category properties]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "cookie-groups",
      "style": {
        "position": "relative"
      }
    }}>{`Cookie groups`}<a parentName="h5" {...{
        "href": "#cookie-groups",
        "aria-label": "cookie groups permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`group[].groupId`}</inlineCode>{` / `}<inlineCode parentName="td">{`group[].commonGroup`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Both optional. If a common group is used, then the `}<inlineCode parentName="td">{`commonGroup`}</inlineCode>{`. If this is a custom group provide an `}<inlineCode parentName="td">{`groupId`}</inlineCode>{`. Not required, if there are no cookies that have a `}<inlineCode parentName="td">{`groupId`}</inlineCode>{` set.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`group[].title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only for custom groups. Title of the group. Cannot be set for a common group. `}<strong parentName="td">{`Must be unique.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`group[].text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only for custom groups. Cannot be set for a common group. Description of the group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`group[].expandAriaLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only for custom groups. Cannot be set for a common group. Aria-label for the button to show more details of the group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`group[].checkboxAriaDescription`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only for custom groups. Cannot be set for a common group. Aria-description for the checkbox for giving consent for all cookies in the group`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`group[].cookies`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional list of cookies in this group.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 3:Cookie group properties]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "cookie-information",
      "style": {
        "position": "relative"
      }
    }}>{`Cookie information`}<a parentName="h5" {...{
        "href": "#cookie-information",
        "aria-label": "cookie information permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cookie[].groupId`}</inlineCode>{` / `}<inlineCode parentName="td">{`cookie[].commonGroup`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The group this cookie belongs to. Optional. Not needed when the cookie is defined in an `}<inlineCode parentName="td">{`optionalCookies`}</inlineCode>{` / `}<inlineCode parentName="td">{`requiredCookies.group`}</inlineCode>{` If `}<inlineCode parentName="td">{`commonGroup`}</inlineCode>{` is used and the group itself is not listed, it is auto-added from `}<inlineCode parentName="td">{`content.json`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cookie[].commonCookie`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Use when the cookie is common.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cookie[].id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the consent. This ID is saved in the consent cookie. `}<strong parentName="td">{`Must be unique.`}</strong>{` Only for custom cookies. Cannot be set for a common cookie.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cookie[].name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Readable cookie name. Only for custom cookies. Cannot be set for a common cookie.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cookie[].hostName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hostname where it is used. Only for custom cookies. Cannot be set for a common cookie.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cookie[].description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cookie usage description. Only for custom cookies. Cannot be set for a common cookie.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cookie[].expiration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When does the cookie expire. Only for custom cookies. Cannot be set for a common cookie.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 4:Cookie information properties]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "callbacks",
      "style": {
        "position": "relative"
      }
    }}>{`Callbacks`}<a parentName="h5" {...{
        "href": "#callbacks",
        "aria-label": "callbacks permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h5>
    <p>{`The `}<inlineCode parentName="p">{`contentSource`}</inlineCode>{` object passed to the component can also include callback functions:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Arguments`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onAllConsentsGiven`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Called when the user has given all consents. Not called if consents are not asked for.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object with `}<inlineCode parentName="td">{`cookie.id`}</inlineCode>{` as the key and true/false as value `}<inlineCode parentName="td">{`{matomo: true, marketing: false}`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onConsentsParsed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Called when cookie consents are read from the browser, before the cookie modal will possibly be shown. Called always even if consent has been given.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object with `}<inlineCode parentName="td">{`cookie.id`}</inlineCode>{` as the key and true/false as value `}<inlineCode parentName="td">{`{matomo: true, marketing: false}`}</inlineCode>{` and boolean indicating are all consents handled. If true, the modal will not be shown.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 5:Component object callbacks]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "strings-and-language",
      "style": {
        "position": "relative"
      }
    }}>{`Strings and language`}<a parentName="h5" {...{
        "href": "#strings-and-language",
        "aria-label": "strings and language permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h5>
    <p>{`The following strings can be overridden if needed. Note that you also need to provide translations if you override these. It is recommended to use the provided strings.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`texts.sections.main.title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Top level heading.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`texts.sections.main.text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Main description. `}<strong parentName="td">{`The most common override because this is service-specific.`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`texts.sections.details.title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Heading shown in the details. Seen when settings are opened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`texts.sections.details.text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description shown in the details. Seen when settings are opened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 6:Main text content overrides]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ui.showSettings`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text on the show/hide settings -button when settings are hidden.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ui.hideSettings`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text on the show/hide settings -button when settings are shown.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ui.approveAllConsents`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Button text for giving consent to all cookies. Shown when settings are closed and the user cannot give optional consents.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ui.approveRequiredAndSelectedConsents`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Button text for giving consent to required cookies and selected cookies. Shown when settings are open.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ui.approveOnlyRequiredConsents`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Button text for giving consent to only to required cookies. All optional cookie consents are revoked.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ui.readMore`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text shown on a button when modal loses focus and is shrunk.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 7:User interface string overrides]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tableHeadings.name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Title of the “name” column in the cookie description.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tableHeadings.hostName`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Title of the “hostName” column in the cookie description.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tableHeadings.description`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Title of the “description” column in the cookie description.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tableHeadings.expiration`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Title of the “expiration” column in the cookie description.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 8:Table heading string overrides]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`The consent banner language is controlled via a language dropdown element. Note that the current language is passed in as `}<inlineCode parentName="p">{`content.currentLanguage`}</inlineCode>{` and that value is stored into `}<inlineCode parentName="p">{`language.current`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language.languageOptions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`List of selectable languages. Format: `}<inlineCode parentName="td">{`[{ code: 'fi', label: 'Suomeksi (FI)' }]`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language.current`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Current language.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language.languageSelectorAriaLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aria-label for the language selector button.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`language.onLanguageChange`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback called when a new language is selected. Called with new language.code as the argument.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 9:Language properties]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "saving-consents",
      "style": {
        "position": "relative"
      }
    }}>{`Saving consents`}<a parentName="h3" {...{
        "href": "#saving-consents",
        "aria-label": "saving consents permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Consents that the user has given are saved to a `}<inlineCode parentName="p">{`city-of-helsinki-cookie-consents`}</inlineCode>{` cookie.`}</p>
    <p>{`Cookies are always strings and consents are stored as a JSON-formatted string such as `}<inlineCode parentName="p">{`{"cookie-id":"true","another-cookie-id":"false"}`}</inlineCode>{`.`}</p>
    <p>{`The ID of the cookie is stored as `}<inlineCode parentName="p">{`cookie.id`}</inlineCode>{`. `}<inlineCode parentName="p">{`True`}</inlineCode>{` means the user has given consent for that cookie. All required cookies are always stored `}<inlineCode parentName="p">{`true`}</inlineCode>{`, but other sites may list a common cookie optional, so the stored consent is not `}<inlineCode parentName="p">{`true`}</inlineCode>{` by default.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      